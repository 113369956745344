// import $ from 'jquery';

// $('.js-wow-view-more-period-open').click(function(){
//     $('.js-wow-view-more-period-item').removeClass('uk-hidden').addClass('wow-attr-visible');
//     $(this).addClass('uk-hidden');
//     $('.js-wow-view-more-period-close').removeClass('uk-hidden');
// });
// $('.js-wow-view-more-period-close').click(function(){
//     $('.js-wow-view-more-period-item').addClass('uk-hidden').removeClass('wow-attr-visible');
//     $(this).addClass('uk-hidden');
//     $('.js-wow-view-more-period-open').removeClass('uk-hidden');
//     $('.js-wow-view-more-period-subitem[aria-hidden="false"]').each(function(){
//         $(this).prev().find('[aria-hidden="false"]')[0].click();
//     });
// });